<template>
  <div class="card group-passing-table">
        <div class="card-header">
          <div class="card-title">
            <span v-if="group">{{ group.name }}</span> <br/>
            <span class="users-count" v-if="group && group.users"
            >Количество участников: {{ group.users.length }}</span
            >
          </div>
          <div class="tools">
            <button
                v-if="getRight('downloadReports')"
                @click="getReports"
                class="btn btn-dark"
            >
              Скачать все отчеты
              <i class="fas fa-box-archive"></i>
            </button>
            <router-link
                :to="`/group/edit/${route.params.id}`"
                class="btn btn-accent"
            >Редактировать
            </router-link>
          </div>
        </div>
        <div class="card-header card-header-divider justify-content-between">
          <div class="flex justify-content-start align-item-center control-btns">
            <control-visible-columns :name="'group-passing'" :hideColumns="hideColumns"/>
            <Popper placement="bottom" arrow class="action-popper">
              <button
                  :disabled="!checkedList.length"
                  class="btn btn-w btn-sm action-btn"
              >
                Выдать тесты
              </button>
              <template #content>
                <giving-tests-mini
                    v-if="group"
                    :users="group.users"
                    :groupId="group.id"
                    :checkedList="checkedList"
                    :testOptionsCustom="testOptions"
                    @clearChecked="clearChecked"
                />
              </template>
            </Popper>

            <button
                class="btn btn-w btn-sm action-btn"
                @click="takePassing"
                :disabled="!checkedList.length"
            >
              Забрать тесты
            </button>
            <button
                @click="remindAboutTest"
                :disabled="!checkedList.length"
                class="btn btn-accent btn-sm"
            >
              Напомнить о прохождении
            </button>
            <button
                @click="sendReports"
                :disabled="!checkedList.length"
                class="btn btn-accent btn-sm"
            >
              Отправить отчеты
            </button>
          </div>
        </div>
        <div class="card-header card-header-divider justify-content-between course-control">
          <div class="flex align-item-center course-control__btns flex-1">
            <custom-select-2
                :inputWidth="100"
                v-model="currentCourse"
                name="courseName"
                :options="['Выберите курс', ...courseOptions]"
            />
            <button
                @click="giveCourse"
                :disabled="!checkedList.length"
                class="btn btn-accent btn-sm"
            >
              Выдать курс
            </button>
            <button
                @click="takeCourse"
                :disabled="!checkedList.length"
                class="btn btn-w btn-sm"
            >
              Забрать курс
            </button>
          </div>
          <div class="select-count">
            <custom-select
                :labelWidth="40"
                name="rowsCount"
                v-model="countOnPage"
                label="Кол-во пользователей на странице"
                :options="[10, 15, 20, 50, 75]"
            />
          </div>
        </div>
        <div class="card-header card-header-divider justify-content-betweencourse-control">
          <div class="flex align-item-center course-control__btns flex-1">
            <button
                @click="giveTrajectory"
                :disabled="!checkedList.length"
                class="btn btn-accent btn-sm"
            >
              Выдать траекторию
            </button>
            <button
                @click="takeTrajectory"
                :disabled="!checkedList.length"
                class="btn btn-w btn-sm"
            >
              Забрать траекторию
            </button>
          </div>
        </div>
        <div class="card-body">
          <button
              @click="showTransferPopup"
              class="btn btn-accent btn-sm quota-btn"
          >
            Передать квоты в другую группу
          </button>
          <quota-tables
              v-if="groupQuotas.test_passings && groupQuotas.course_passings"
              :quota="groupQuotas"
              :courseOptions="courseOptions"
              :testOptions="testOptions"
              :trajectoriesOptions="trajectoriesOptions"
          />
        </div>

        <div class="card-body">
          <div class="flex quota-tables align-item-center flex-column">
            <div class="table-sm-container w-full">
              <div
                  v-if="!showFilters"
                  class="hide-btn show"
                  @click="showFilters = true"
              >
                Показать фильтры <i class="fal fa-angle-down"></i>
              </div>
              <div v-else class="hide-btn hide" @click="showFilters = false">
                Скрыть фильтры <i class="fal fa-angle-up"></i>
              </div>
            </div>
          </div>
        </div>
        <div v-show="showFilters">
          <div class="card-header justify-content-start head-sort">
            <custom-input
                :delay="800"
                name="staff-passings-sort"
                placeholder="Имя"
                label="Поиск:"
                cleanable
                v-model="searchParams.searchField"
            />
            <custom-select-2
                v-model="searchParams.searchRole"
                name="role"
                label="Уровень должности:"
                :options="positionOptions"
            />
            <custom-select-2
                v-model="searchParams.searchPosition"
                name="position"
                label="Роль:"
                :options="[
                               'Не выбрано',
                              'Сотрудник',
                              'Администратор']"
            />
          </div>
          <div class="card-header justify-content-start head-sort">
            <custom-select-2
                v-model="searchParams.searchStatus"
                name="status"
                label="Статус:"
                :options="statusOptions"
            />
            <custom-select-2
                v-model="searchParams.searchReport"
                name="report"
                label="Тип отчета:"
                :options="['Не выбрано', ...testTextOptions]"
            />
            <custom-select2
                label="Курс"
                v-model="searchParams.searchExistCourse"
                name="existCourse"
                :options="['Не выбрано', ...courseTextOptions]"
            />

          </div>
          <div class="card-header justify-content-start">
            <div class="date_block">
              <span>Дата прохождения</span>
              <custom-input v-model="searchParams.dateFrom" type="date" :inputWidth="80" :labelWidth="20" label="От"
                            name="dateFrom"/>
              <custom-input v-model="searchParams.dateTo" type="date" :inputWidth="80" :labelWidth="20" label="До"
                            name="dateTo"/>
            </div>
          </div>
          <div class="card-header justify-content-start">
            <custom-checkbox
                v-model="searchParams.searchCourse"
                :label="'Не имеет курсов'"
                :checked="searchParams.searchCourse"
            />
            <custom-checkbox
                v-model="searchParams.searchAllowReport"
                :label="'Не отправлен отчет'"
                :checked="searchParams.searchAllowReport"
            />
            <custom-checkbox
                v-model="searchParams.searchReadyForTest"
                :label="'Готов к тестированию'"
                :checked="searchParams.searchReadyForTest"
            />
          </div>
          <div class="card-header justify-content-start">
            <button @click="clearAllSearch" class="btn btn-w reset-btn">
              Очистить
            </button>
          </div>
        </div>

    <div class="flex w-full justify-content-end">
      <transition name="fade">
        <div v-if="checkedList.length" class="selected-count">
          Выделено: {{ checkedList.length }}
        </div>
      </transition>
    </div>
    <div class="card-body" v-if="group">
      <div v-if="shownData && shownData.length">
        <base-table
            v-if="shownData.length"
            :columns="passingFields.value"
            :rows="shownData"
            :rowSelect="onRowSelected"
            :checkedList="checkedList"
            :actionOptions="testOptions"
            :hiddenColumns="hiddenColumns"
            @sort="(data) => {
              sortValue = data.sortValue;
              sortDirection = data.direction;
            }"
        />
        <table-pagination
            v-if="shownData.length"
            :key="updatePagination"
            :totalPages="1"
            :countOnPage="countOnPage"
            :total="totalResults ?? rowData.length"
            :count="countOnPage > shownData.length ? shownData.length : countOnPage"
            @changePage="changePage"
        />
      </div>
      <div v-else class="no_results">
        <p>Нет результатов</p>
      </div>
    </div>

    <div v-else class="table-preloader">
      <preloader/>
    </div>

  </div>
  <course-popup/>
  <transfer-quota-to-group-popup
      :courseOptions="courseOptions"
      :testOptions="testOptions"
      :trajectoriesOptions="trajectoriesOptions"
  />
</template>

<script>
import {
  computed,
  onBeforeMount,
  reactive,
  ref,
  watch,
} from "@vue/runtime-core";
import {useStore} from "vuex";
import {useRoute, useRouter} from "vue-router";
import CustomInput from "@/components/Forms/Fields/CustomInput.vue";
import CustomSelect2 from "@/components/Forms/Fields/CustomSelect2.vue";
import {notify} from "@kyvg/vue3-notification";
import CoursePopup from "@/feature/group-control/popups/CoursePopup.vue";
import CustomSelect from "@/components/Forms/Fields/CustomSelect.vue";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";
import Preloader from "@/components/Technical/Preloader.vue";
import useQuotaCount from "@/mixins/useQuotaCount";
import GivingTestsMini from "@/feature/group-control/popups/GivingTestsMini.vue";
import QuotaTables from "@/feature/group-control/QuotaTables.vue";
import TablePagination from "@/components/OtherElements/TablePagination.vue";
import TransferQuotaToGroupPopup from "@/feature/group-control/popups/TransferQuotaToGroupPopup.vue";
import {getTestStatus} from "@/feature/group-control/utils/groupPassingUtils";
import reportTranslate from "@/mixins/dictionaries/reportTranslate";
import useCheckRights from "@/mixins/useCheckRights";
import sortData from "@/mixins/sortData";
import BaseTable from "@/components/Tables/BaseTable.vue";
import ControlVisibleColumns from "@/components/OtherElements/ControlVisibleColumns.vue";

export default {
  name: "group-passings-table",
  components: {
    BaseTable,
    CustomInput,
    CustomSelect2,
    CoursePopup,
    CustomSelect,
    CustomCheckbox,
    Preloader,
    GivingTestsMini,
    QuotaTables,
    TablePagination,
    ControlVisibleColumns,
    TransferQuotaToGroupPopup,
  },
  setup() {
    const store = useStore(),
        route = useRoute(),
        router = useRouter(),
        columnsName = ref(null),
        {getCourseListGroup, getTestList, getTrajectoriesList} = useQuotaCount(),
        {getRight} = useCheckRights();

    const countOnPage = ref(parseInt(localStorage.getItem("countOnPagePassings") )|| 10),
        searchParams = reactive({
          searchField: "",
          searchStatus: "Не выбрано",
          searchPosition: "Не выбрано",
          searchReport: "Не выбрано",
          searchCourse: false,
          searchAllowReport: false,
          searchReadyForTest: false,
          searchExistCourse: "Не выбрано",
          searchRole: "Не выбрано",
          dateFrom: "",
          dateTo: "",
        }),
        checkedList = ref([]),
        isPreloader = ref(false),
        positionOptions = ref([]),
        showFilters = ref(false),
        activeFilter = ref(false),
        statusOptions = [
          "Не выбрано",
          "Тест не выдан",
          "Тест не пройден",
          "В процессе прохождения",
          "Тест завершен",
        ],
        courseOptions = ref([]),
        testOptions = ref([]),
        trajectoriesOptions = ref([]),
        currentCourse = ref("Выберите курс");
    const positionList = computed(() => store.state.test.positionList),
        passingsCount = computed(() => store.state.test.passingsCount),
        group = computed(() => store.state.group.group.group),
        groupQuotas = computed(() => store.state.group.groupQuotas),
        courseTextOptions = computed(() =>
            courseOptions.value.map((course) => course.text)
        ),
        testTextOptions = computed(() =>
            testOptions.value.map((test) => test.text)
        ),
        rowData = computed(() => {
          return group.value.users.map((staff) => {
            return {
              id: staff.id,
              name: staff.name,
              position: staff.position,
              role: staff.role_russian,
              status: getTestStatus(staff),
              passing: staff.course_passings,
              class: [staff.course_passings, staff.trajectories],
              edit: staff.id,
              onlineFrom: staff.online_from,
              reportLink: staff.last_test_passing
                  ? staff.last_test_passing.report_link
                  : "",
              passingDate: staff.last_success_test_passing_date,
              sendPassingDate: staff.last_test_passing
                  ? staff.last_test_passing.activated_at
                  : "",
              code: staff.last_test_passing ? staff.last_test_passing.code : "",
              report: staff.last_test_passing
                  ? reportTranslate[staff.last_test_passing.report_type]
                  : "",
              allowViewReport: staff.last_test_passing
                  ? staff.last_test_passing.allow_view_report
                  : null,
              readyForTest: staff.ready_to_new_test,
            };
          });
        });

    const onRowSelected = (id, selectAll) => {
      const isInclude = checkedList.value.includes(id);
      if (id && isInclude) {

        if (!selectAll) {
          checkedList.value = checkedList.value.filter(
              (currentId) => currentId !== id
          );
        }
      } else {
        checkedList.value.push(id);
      }
    };

    let hiddenColumns = ref([]);

    const hideColumns = (columns) => {
      hiddenColumns.value = [];
      for (const [key, value] of Object.entries(columns)) {
        if (!value) {
          hiddenColumns.value.push(key);
        }
      }
    }

    const takePassing = () => {
      if (
          !group.value.users.every(
              (staff) =>
                  !checkedList.value.includes(staff.id) ||
                  (staff.last_test_passing &&
                      staff.last_test_passing.status !== "finished")
          )
      ) {
        showNotification(
            "Нельзя забрать у сотрудников, у которых нет теста или тест уже пройден"
        );
      } else {
        store
            .dispatch("group/takeStaffPassings", {
              id: group.value.id,
              staffIds: checkedList.value,
              count: checkedList.value.length,
            })
            .then(() => {
              store.dispatch("group/getGroupQuotas", route.params.id);
            });
      }
    };
    const giveTrajectory = () => {
          store
              .dispatch("group/giveStaffTrajectory", {
                id: group.value.id,
                staffIds: checkedList.value,
                count: checkedList.value.length
              })
              .then(() => {
                store.dispatch("group/getGroupQuotas", route.params.id);
              })
        },
        takeTrajectory = () => {
          store
              .dispatch("group/takeStaffTrajectory", {
                id: group.value.id,
                staffIds: checkedList.value,
                count: checkedList.value.length,
              })
              .then(() => {
                store.dispatch("group/getGroupQuotas", route.params.id);
              })
        }
    const giveCourse = () => {
          if (currentCourse.value === "Выберите курс") {
            showNotification("Выберите курс");
          } else
            store
                .dispatch("group/giveStaffCourses", {
                  id: group.value.id,
                  staffIds: checkedList.value,
                  course: currentCourse.value,
                  count: checkedList.value.length,
                  name: courseOptions.value.find(
                      (course) => course.id === currentCourse.value
                  ),
                  source: courseOptions.value.find(
                      (course) => course.id === currentCourse.value
                  ).source
                })
                .then(() => {
                  store.dispatch("group/getGroupQuotas", route.params.id);
                });
        },
        takeCourse = () => {
          if (currentCourse.value === "Выберите курс") {
            showNotification("Выберите курс");
          } else
            store
                .dispatch("group/takeStaffCourses", {
                  id: group.value.id,
                  staffIds: checkedList.value,
                  course: currentCourse.value,
                  count: checkedList.value.length,
                })
                .then(() => {
                  store.dispatch("group/getGroupQuotas", route.params.id);
                });
        }
    const remindAboutTest = () => {
      store
          .dispatch("group/remindAboutTest", {
            id: group.value.id,
            staffIds: checkedList.value,
          })
          .then(() => {
            clearChecked();
          });
    };
    const sendReports = () => {
      store
          .dispatch("group/allowToViewReport", {
            id: group.value.id,
            staffIds: checkedList.value,
          });
    };
    const showNotification = (text, type = "warning") => {
      notify({
        title: text,
        type,
      });
    };

    const clearAllSearch = () => {
      searchParams.searchField = "";
      searchParams.searchPosition = "Не выбрано";
      searchParams.searchRole = "Не выбрано";
      searchParams.searchStatus = "Не выбрано";
      searchParams.searchReport = "Не выбрано";
      searchParams.searchExistCourse = "Не выбрано";
      searchParams.searchCourse = false;
      searchParams.searchAllowReport = false;
      searchParams.searchReadyForTest = false;
      searchParams.dateFrom = "";
      searchParams.dateTo = "";
    };

    const clearChecked = () => checkedList.value = [];

    const getReports = () => {
      const codes = [];
      rowData.value.forEach((el) => {
        if (el.status === "Тест завершен" || el.reportLink) {
          codes.push(el.code);
        }
      });

      if (!codes.length) {
        notify({
          type: "warning",
          title: "Нет доступных для скачивания отчетов",
        });
        return;
      }
      store.dispatch("group/getReportsArchive", {
        group: group.value.id,
        name: group.value.name,
        codes,
      });
    };

    const passingFields = reactive({
      value: [
        {
          field: "check",
          headerName: "",
          checkAll: true,
        },
        {
          field: "name",
          headerName: "Имя",
        },
        {
          field: "onlineFrom",
          headerName: "Дата последней авторизации",
        },
        {
          field: "report",
          headerName: "Тип отчёта",
        },
        {
          field: "position",
          headerName: "Уровень должности",
        },
        {
          field: "role",
          headerName: "Роль",
        },
        {
          field: "sendPassingDate",
          headerName: "Дата отправки теста",
          sortable: true,
        },
        {
          field: "status",
          headerName: "Статус",
        },
        {
          field: "passingDate",
          headerName: "Дата прохождения",
        },
        {
          field: "class",
          headerName: "Обучение",
        },
        {
          field: "eiToken",
          headerName: "Зайти под учётную запись",
        },
        {
          field: "edit",
          headerName: "",
          dest: "staff",
          res: "edit",
          action: true,
          groupId: parseInt(route.params.id),
        },
      ]
    })

    const pageDataStart = ref(0);
    const updatePagination = ref(false);
    const totalResults = ref();
    const sortValue = ref('');
    const sortDirection = ref('');

    const shownData = computed(() => {
      let data = rowData.value ?? [];

      if (data.length) {

        if (searchParams.searchField && searchParams.searchField.length >= 3) {
          data = data.filter((el) =>
              el.name.toLowerCase().includes(searchParams.searchField.toLowerCase())
          );
        }

        if (searchParams.searchPosition && searchParams.searchPosition !== 'Не выбрано') {
          data = data.filter((el) =>
              el.position === searchParams.searchPosition
          );
        }

        if (searchParams.searchRole && searchParams.searchRole !== 'Не выбрано') {
          data = data.filter((el) =>
              el.role === searchParams.searchRole
          );
        }

        if (searchParams.searchStatus && searchParams.searchStatus !== 'Не выбрано') {
          data = data.filter((el) =>
              el.role === searchParams.searchStatus
          );
        }

        if (searchParams.searchReport && searchParams.searchReport !== 'Не выбрано') {
          data = data.filter((el) =>
              el.report === searchParams.searchReport
          )
        }

        if (searchParams.searchExistCourse && searchParams.searchExistCourse !== 'Не выбрано') {
          data = data.filter((el) => {
            let courses = typeof el.class[0] === 'object' ? Object.values(el.class[0]) : el.class[0];
            return courses.find((course) => course.name === searchParams.searchExistCourse);
          })
        }

        if (searchParams.searchAllowReport) {
          data = data.filter((el) =>
              !el.allowViewReport && el.status === 'Тест завершён'
          )
        }

        if (searchParams.searchCourse) {
          data = data.filter(
              (el) => !el.class || (!el.class[0].length && !el.class[1].length)
          )
        }

        if (searchParams.searchReadyForTest) {
          data = data.filter(
              (el) => el.readyForTest
          )
        }

        if (searchParams.dateFrom || searchParams.dateTo) {
          data = data.filter((el) => {
            let date = Date.parse(el.passingDate);

            if (date >= (Date.parse(searchParams.dateFrom) || 0) && date <= (Date.parse(searchParams.dateTo) || Number.MAX_SAFE_INTEGER)) {
              return true;
            }
          });
        }

        data = sortData(data, 'name', 'up');

        totalResults.value = data.length;
        data = data.slice(pageDataStart.value, pageDataStart.value + countOnPage.value);
      }

      return data;
    });

    const changePage = (page) => {
      pageDataStart.value = (page - 1) * countOnPage.value;
    };

    onBeforeMount(() => {
      store.dispatch("group/getGroup", route.params.id);
      store.dispatch("group/getGroupQuotas", route.params.id).then(() => {

        courseOptions.value = getCourseListGroup(groupQuotas.value.course_passings);
        testOptions.value = getTestList(groupQuotas.value.test_passings);
        trajectoriesOptions.value = getTrajectoriesList(groupQuotas.value.trajectories);
      });
      store.dispatch("test/getPositionList").then(() => {
        positionOptions.value = positionList.value.map(
            (position) => position.text
        );
        positionOptions.value.unshift("Не выбрано");
      });
    });

    watch(countOnPage, () => {
      countOnPage.value = parseInt(countOnPage.value);
      localStorage.setItem("countOnPagePassings", countOnPage.value);
    });

    watch(
        () => [
          searchParams.searchField,
          searchParams.searchPosition,
          searchParams.searchRole,
          searchParams.searchStatus,
          searchParams.searchReport,
          searchParams.searchExistCourse,
          searchParams.searchCourse,
          searchParams.searchAllowReport,
          searchParams.searchReadyForTest,
          searchParams.dateTo,
          searchParams.dateFrom,
          sortDirection.value,
          sortValue.value,
          countOnPage.value
        ],
        () => {
          totalResults.value = 0;
          pageDataStart.value = 0;
          updatePagination.value = !updatePagination.value;
        },
    )

    const showTransferPopup = () => {
      store.commit("ui/showTransferQuotasPopup", true);
    };

    const url = process.env.VUE_APP_FRONT;

    return {
      route,
      router,
      countOnPage,
      checkedList,
      isPreloader,
      statusOptions,
      searchParams,
      takePassing,
      positionOptions,
      positionList,
      clearAllSearch,
      passingsCount,
      group,
      testTextOptions,
      showFilters,
      remindAboutTest,
      groupQuotas,
      getRight,
      trajectoriesOptions,
      courseOptions,
      testOptions,
      courseTextOptions,
      currentCourse,
      activeFilter,

      giveCourse,
      takeCourse,
      sendReports,
      getReports,
      giveTrajectory,
      takeTrajectory,
      rowData,
      onRowSelected,

      columnsName,
      url,

      showTransferPopup,
      hideColumns,

      changePage,
      sortValue,
      sortDirection,
      shownData,
      totalResults,
      updatePagination,
      passingFields,
      clearChecked,
      hiddenColumns
    };
  },
};
</script>

<style lang="scss">
.group-passing-table {
  .ag-body-viewport {
    min-height: 360px;
  }
}
</style>

<style lang="scss" scoped>
.checkbox-cell {
  padding-bottom: 4px;
}

.group-passing-table {
  .control-btns .btn:not(:last-child),
  .action-btn {
    margin-right: 10px;
  }

  .users-count {
    font-size: 15px;
    font-weight: 300;
  }

  .select-count {
    min-width: 345px;
  }

  .reset-btn {
    font-size: 16px;
  }

  .quota-btn {
    margin-bottom: 16px;
  }

  .head-sort:nth-of-type(3) {
    padding-top: 0;
  }

  .hide-btn {
    margin: 0 20px;
  }

  .selected-count {
    margin-right: 40px;
  }

  .btn-accent {
    margin-left: 0;
  }

  .date_block span {
    font-size: 15px;
  }

  .no_results {
    padding: 40px 10px;
  }

  .date_block {
    width: 100%;
    display: flex;
    padding: 0 10px;
    align-items: center;
    column-gap: 10px;
  }

  .course-control {
    .form-group {
      margin-right: 0 !important;
      width: auto !important;
    }

    &__btns {
      .btn {
        margin-right: 10px;
      }
    }

    @media (max-width: 1440px) {
      flex-direction: column;
      align-items: flex-start;

      .form-group {
        min-width: 380px;
      }
    }

    @media (max-width: 1040px) {
      .form-group {
        min-width: 100% !important;
      }
      &__btns {
        width: 100%;
        flex-direction: column;
        align-items: flex-start;

        .btn {
          margin-top: 15px;
          width: 97%;
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .card-header {
      .form-group:not(:last-child) {
        margin-right: 15px;
      }

      .form-group {
        min-width: 380px;
      }
    }
  }

  @media (max-width: 1660px) {
    .head-sort {
      flex-direction: column;
      align-items: flex-start;
    }
    .reset-btn {
      margin-top: 15px;
      margin-left: 0;
    }

    .form-group {
      width: 100%;
    }
  }

  @media (max-width: 1480px) {
    .control-btns {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      button:not(:last-child),
      .action-btn {
        margin-right: 0;
        margin-bottom: 10px;
      }
    }

    .selected-count {
      margin-top: 15px;
    }

    .date_block span {
      width: 35%;
    }
  }

  @media (max-width: 978px) {
    .card-header-divider {
      flex-direction: column;
      align-items: flex-start;
    }
    .select-count {
      min-width: 100%;
      margin-top: 15px;
    }

    .date_block {
      padding: 0;
      flex-direction: column;
    }

    .date_block span {
      width: 100%;
      text-align: center;
      font-size: 15px;
    }

  }

  @media (min-width: 426px) {
    .hide-btn {
      margin: 0 30px;
    }
  }
}
</style>
