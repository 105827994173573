<template>
  <Popper class="columns-control-popper">
    <button class="btn btn-w columns-control">
      <img src="@/assets/images/icons/settings.svg" alt="Иконка настроек"/>
    </button>
    <template #content>
      <custom-checkbox
          v-for="(value, key) in visibleColumns"
          :key="key"
          v-model="visibleColumns[key]"
          :checked="visibleColumns[key]"
          :label="columnsDictionary[key]"
      />
    </template>
  </Popper>
</template>

<script>
import {onBeforeMount, reactive, watch} from "@vue/runtime-core";
import visibleColumnsDictionary from "@/mixins/dictionaries/visibleColumnsDictionary";
import CustomCheckbox from "@/components/Forms/Fields/CustomCheckbox.vue";

export default {
  components: {CustomCheckbox},
  name: "control-visible-columns",
  props: {
    name: {
      type: String,
    },
    hideColumns: Function,
  },
  setup(props) {
    const columnsDictionary = visibleColumnsDictionary;
    const visibleColumns = reactive({
      passingDate: true,
      name: true,
      status: true,
      class: true,
    });

    watch(visibleColumns, () => {
      props.hideColumns(visibleColumns);
      localStorage.setItem(props.name, JSON.stringify(visibleColumns));
    });

    onBeforeMount(() => {
      if (localStorage.getItem(props.name)) {
        let options = localStorage.getItem(props.name);
        if (options) {
          options = JSON.parse(options);
          for (let key in visibleColumns) {
            visibleColumns[key] = options[key] ? options[key] : false;
          }
        }
      }
    });

    return {
      columnsDictionary,
      visibleColumns,
    };
  },
};
</script>

<style scoped lang="scss">
.columns-control {
  font-size: 16px;
  height: 40px;
  width: 40px;
  margin-right: 10px;
  padding: 9px;

  img {
    height: 100%;
    width: 100%;
  }
}
</style>