<template>
    <transition name="fade">
        <div
                v-if="showCoursePopup"
                class="popup popup__backdrop"
                @mousedown.self="closePopup"
        >
            <div class="popup__content">
                <div class="course-popup card">
                    <div class="course-popup__header card-header card-header-divider">
                        <div>Обучение</div>
                        <button @click="closePopup" class="course-popup__close-btn">
                            <!-- <img src="@/assets/images/icons/close.svg" alt="" /> -->
                            <svg
                                    fill="#3d3d3d"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 50 50"
                                    width="50px"
                                    height="50px"
                            >
                                <path
                                        d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z"
                                />
                            </svg>
                        </button>
                    </div>
                    <div class="course-popup__title">Курсы:</div>
                    <div class="course-popup__body">
                        <table v-if="courses" class="table table-striped table-hover">
                            <tbody>
                            <tr v-for="course in courses" :key="course.id">
                                <td>{{ course.name }}</td>
                                <td style="width: 120px">{{ getCourseStatus(course) }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {computed} from "@vue/runtime-core";
import {useStore} from "vuex";
import {getCourseStatus} from "@/feature/group-control/utils/coursePassingUtils";

export default {
    name: "course-popup",
  methods: {getCourseStatus},
    setup() {
        const store = useStore();
        const closePopup = () => {
            store.commit("ui/showCoursePopup", {show: false});
        };

        const showCoursePopup = computed(() => store.state.ui.showCoursePopup),
            courses = computed(() => store.state.ui.currentCourses)

        return {
            closePopup,
            showCoursePopup,
            courses
        };
    },
};
</script>

<style lang="scss" scoped>
.course-popup {
  width: 100%;
  min-height: 100%;
  margin-bottom: 0;
  border-radius: 0;

  &__header {
    font-size: 26px;
    font-weight: 400;
    padding: 24px 0 14px;
    margin: 0 40px;
    line-height: 1.738;
    flex-direction: row;
  }

  &__close-btn {
    height: 45px;
    width: 40px;

    svg {
      max-width: 100%;
    }

    &:hover svg {
      fill: var(--hover-color);
    }
  }

  &__title {
      padding: 10px 0 10px 40px;
  }

  &__body {
    padding: 0 40px;
    overflow-x: auto;

    .table {
      min-width: unset;
    }
  }

  @media (max-width: 425px) {
    &__header {
      margin: 0 20px;
    }
    &__body {
      padding: 0 20px;
    }
  }
}
</style>